import React from "react"

const Steam = () => (
<svg
   xmlns="https://www.w3.org/2000/svg"
   viewBox="0 0 2200.7334 682.37335"
   height="682.37335"
   width="2200.7334"
   id="svg2"
   version="1.1"><defs
     id="defs6" /><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,682.37333)"
     id="g10"><g
       transform="scale(0.1)"
       id="g12"><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="M 2527.06,5117.83 C 1195.01,5117.83 103.719,4081.33 0,2764.11 l 1359.15,-567 c 115.14,79.41 254.28,125.91 403.95,125.91 13.35,0 26.72,-0.38 40.01,-1.11 l 604.47,884.03 c 0,4.23 -0.12,8.29 -0.12,12.52 0,532.1 428.99,965.06 956.31,965.06 527.33,0 956.33,-432.96 956.33,-965.06 0,-532.13 -429,-965.14 -956.33,-965.14 -7.26,0 -14.49,0.25 -21.67,0.39 l -862.01,-620.7 c 0.43,-11.39 0.75,-22.83 0.75,-34.29 0,-399.46 -321.98,-724.411 -717.74,-724.411 -347.41,0 -637.93,250.301 -703.78,581.741 L 87.293,1861.53 C 388.277,787.43 1366.2,0 2527.06,0 c 1400.51,0 2535.79,1145.74 2535.79,2558.85 0,1413.31 -1135.28,2558.98 -2535.79,2558.98" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="m 1589.11,1235.05 -311.53,129.84 c 55.24,-115.93 150.74,-213.02 277.56,-266.45 274.1,-115.21 590.1,16.05 704.39,292.83 55.27,133.97 55.65,281.76 0.81,415.95 -54.74,134.2 -158.07,238.94 -290.89,294.84 -131.79,55.32 -272.94,53.31 -397.03,6.07 l 321.78,-134.27 c 202.18,-85.09 297.78,-319.32 213.55,-523.32 -84.18,-204.12 -316.5,-300.56 -518.64,-215.49" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="m 4001.01,3218.42 c 0,354.57 -285.92,643.08 -637.24,643.08 -351.38,0 -637.23,-288.51 -637.23,-643.08 0,-354.52 285.85,-642.92 637.23,-642.92 351.32,0 637.24,288.4 637.24,642.92 m -1114.87,1.15 c 0,266.83 214.39,482.99 478.67,482.99 264.48,0 478.74,-216.16 478.74,-482.99 0,-266.76 -214.26,-483 -478.74,-483 -264.28,0 -478.67,216.24 -478.67,483" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="m 7725.97,3343.32 -164.83,-292.38 c -126.94,89.57 -298.93,143.43 -449.15,143.43 -171.65,0 -277.82,-71.68 -277.82,-200.12 0,-156.11 188.68,-192.38 469.06,-293.99 301.41,-107.53 474.69,-234.05 474.69,-512.55 0,-381.15 -297.12,-595.23 -723.95,-595.23 -208.08,0 -459.07,54.22 -652.08,172.59 l 120.27,324.24 c 156.73,-83.64 344.25,-133.12 511.39,-133.12 225.29,0 332.38,83.84 332.38,207.89 0,141.84 -163.29,184.57 -426.94,272.6 -300.36,101.22 -508.56,234.08 -508.56,542.42 0,347.62 275.96,547.35 673.03,547.35 276.65,0 499.13,-88.52 622.51,-183.13" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="M 9014.3,3158.58 V 1624.54 h -387.99 v 1534.04 h -563.37 v 335.84 H 9575.86 V 3158.58 H 9014.3" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="m 10393.9,3159.58 v -421.34 h 742.7 V 2402.4 h -742.7 v -438.98 h 861.7 V 1624.54 H 10006 v 1869.88 h 1249.6 v -334.84 h -861.7" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="M 12121.7,1987.56 11999,1624.54 h -406.7 l 694.8,1869.9 h 390.5 l 714.5,-1869.9 h -420.6 l -125.3,363.02 z m 358.4,1060.78 -252.9,-748.66 h 511.3 z" /><path
         style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
         d="m 15383.6,2771.65 -507,-1092.59 h -219 l -498.9,1082.85 V 1624.54 h -371.6 v 1869.88 h 370.7 l 622.9,-1349.76 600.5,1349.76 h 374 V 1624.54 h -371.5 l -0.1,1147.11" /><g
         transform="scale(1.01258)"
         id="g30"><path
           id="path32"
           style={{fill: "#231f20", fillOpacity:1, fillRule: "nonzero", stroke: "none"}}
           d="m 16300.5,3218.04 c 0,-158.68 -117.9,-257.59 -253.1,-257.59 -135.7,0 -253.9,98.91 -253.9,257.59 0,158.67 118.2,256.95 253.9,256.95 135.2,0 253.1,-98.28 253.1,-256.95 m -464.5,0 c 0,-132.76 94.8,-216.11 211.4,-216.11 116.1,0 210.4,83.35 210.4,216.11 0,133.16 -94.3,215.3 -210.4,215.3 -116.4,0 -211.4,-83.12 -211.4,-215.3 m 214.9,131.3 c 65.6,0 87.6,-35.05 87.6,-72.92 0,-34.65 -20.3,-57.94 -45.1,-69.78 l 58.8,-111.2 h -48.1 l -49.6,98.59 h -51.3 v -98.59 h -40 v 253.9 z m -47.2,-118.12 h 44.8 c 28.9,0 46,18.32 46,41.41 0,23.18 -12.3,38.18 -46.1,38.18 h -44.7 v -79.59" /></g></g></g></svg>
)

export default Steam
