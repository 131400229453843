import React from "react"

const NSIcon = () => (
  <svg width="160px" height="170px" viewBox="0 0 160 170" version="1.1">
    <g id="icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Path-3" fill="#EB2889" points="80 83 122 104 120 130 80 110"></polygon>
      <path d="M95,72.5 L160,105 L160,107 L80,147 L60,137 L60,135 L120,105 L86,88 L86,77 L95,72.5 Z M65,72.5 L74,77 L74,90 L40,107 L20,95 L65,72.5 Z M60,0 L80,10 L80,13 L20,42 L0,33 L0,30 L60,0 Z" id="Light" fill="#FF2B95"></path>
      <path d="M60,135 L82,146 L82,168 L80,170 L60,160 L60,135 Z M0,30 L22,41 L22,137 L20,140 L0,130 L0,30 Z" id="Medium" fill="#EB2889"></path>
      <path d="M160,105 L160,130 L80,170 L80,145 L160,105 Z M80,10 L80,35 L40,55 L40,105 L78,86 L78,111 L20,140 L20,40 L80,10 Z" id="Dark" fill="#D1247A"></path>
      <g id="Cube" transform="translate(50.000000, 47.500000)">
        <g id="Group">
          <polygon id="Path" fill="#FFD455" points="0 15 0 32 60 32 60 15 30 0"></polygon>
          <polygon id="Path" fill="#FFBF00" points="0 15 32 31 32 65 30 67.5 0 52.5"></polygon>
          <polygon id="Path" fill="#FFAF17" points="60 15 30 30 30 67.5 60 52.5"></polygon>
        </g>
      </g>
    </g>
  </svg>
)

export default NSIcon
