import React from "react"

const Arrow = () => (
  <svg width="65px" height="69px" viewBox="0 0 65 69">
    <defs>
      <path d="M27.773501,9.83974853 L43.7596228,20.4971631 L43.7596228,20.4971631 C46.0572662,22.0289254 46.6781356,25.1332721 45.1463733,27.4309155 C44.780199,27.980177 44.3088842,28.4514917 43.7596228,28.817666 L27.773501,39.4750806 L27.773501,39.4750806 C25.4758575,41.0068428 22.3715108,40.3859735 20.8397485,38.0883301 C20.2921901,37.2669925 20,36.301954 20,35.3148291 L20,14 L20,14 C20,11.2385763 22.2385763,9 25,9 C25.9871249,9 26.9521634,9.29219014 27.773501,9.83974853 Z" id="arrow-path-1"></path>
      <filter x="-134.7%" y="-79.8%" width="369.4%" height="323.5%" filterUnits="objectBoundingBox" id="arrow-filter-2">
        <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <use fill="black" fillOpacity="1" filter="url(#arrow-filter-2)" xlinkHref="#arrow-path-1" />
      <use fill="#23D199" fillRule="evenodd" xlinkHref="#arrow-path-1" />
    </g>
  </svg>
)

export default Arrow