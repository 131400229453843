import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Footer from "components/components/footer"
import "./default.sass"

const Layout = ({ children, parentClass, hideFooter = false }) => (
  <div className={parentClass}>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Nunito:300,400,700|Rubik:400,400i,700,700i&display=swap"
        rel="stylesheet"
      />
      <link href={`${process.env.SITE_URL}/vendor/pie.css`} rel="stylesheet" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.SITE_URL}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.SITE_URL}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.SITE_URL}/favicons/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${process.env.SITE_URL}/favicons/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${process.env.SITE_URL}/favicons/safari-pinned-tab.svg`}
        color="#161c4f"
      />
      <link
        rel="shortcut icon"
        href={`${process.env.SITE_URL}/favicons/favicon.ico`}
      />
      <meta name="msapplication-TileColor" content="#161c4f" />
      <meta
        name="msapplication-config"
        content={`${process.env.SITE_URL}/favicons/browserconfig.xml`}
      />
      <meta name="theme-color" content="#161c4f" />
    </Helmet>
    {children}
    {hideFooter || <Footer />}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
