import React from "react"

const XboxOne = () => (
<svg version="1.1" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px" height="498" width="2500"
   viewBox="0 0 2500 498">
<path style={{fill: "#333333"}} d="M1599.4,402.3L1504,272.8l-95.3,129.5h-39.3l114.7-156.5l-104.8-142.2h39.3l85.4,116l85.4-116h39.2
  l-104.7,142.2l114.7,156.5H1599.4z M855.8,402.2L741,245.8l104.8-142.2h-39.3l-85.4,116l-85.3-116h-39.2l104.7,142.2L586.5,402.2
  h39.3l95.3-129.5l95.4,129.5H855.8z"/>
<path style={{fill: "#333333"}} d="M1074.2,257.1c-5.9-5-12.5-9.2-19.6-12.5c22.1-11.8,35.9-34.8,35.9-59.8c0-19.1-7-81.3-97.1-81.3H872.1v128.8
  h-40.4c-5.2,6.8-17.1,23.2-21.7,30h62.1v139.9l121.3,0c65.7,0,106.5-33.4,106.5-87.2C1100,291.5,1091.1,271.6,1074.2,257.1
   M905.7,134.7h87.8c23.7,0,63.5,5.9,63.5,47.5c0,31.4-23.2,50.1-63.5,50.1h-87.8V134.7L905.7,134.7z M993.4,371.1h-87.8V262.4h87.8
  c33.3,0,73.1,9.2,73.1,52C1066.5,363.7,1020.7,371.1,993.4,371.1 M1257.8,96.7c-83.9,0-142.6,64.2-142.6,156.1
  c0,92,58.6,156.1,142.6,156.1c83.8,0,142.3-64.1,142.3-156.1C1400.1,160.9,1341.6,96.7,1257.8,96.7 M1257.8,377.3
  c-63.9,0-108.7-51.2-108.7-124.5s44.8-124.5,108.7-124.5c63.8,0,108.4,51.2,108.4,124.5S1321.6,377.3,1257.8,377.3 M74.4,71.6
  l-0.2,0.2v0C26.7,118.6,0,182.4,0.1,249C0,303.5,17.9,356.5,51,399.8c0.3,0.4,0.9,0.7,1.3,0.4c0.4-0.3,0.5-0.8,0.3-1.3
  c-19-58.7,78-201,128.2-260.6c0.2-0.2,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.5-0.8C96.3,52.9,73.8,72.1,74.1,71.8 M423.8,71.6l0.2,0.2v0
  c47.5,46.7,74.1,110.6,74,177.2c0.1,54.5-17.8,107.5-50.9,150.8c-0.3,0.4-0.9,0.7-1.3,0.4c-0.4-0.3-0.4-0.8-0.3-1.3
  c19-58.7-78-201.1-128.1-260.7c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.4,0.2-0.6,0.5-0.8C401.9,52.8,424.3,72.1,424,71.8 M249.1,0
  c50.2,0,91.6,13.8,129.7,36.4c0.5,0.2,0.6,0.8,0.4,1.2c-0.2,0.4-0.7,0.5-1.2,0.4c-48.2-10.4-121.5,31.2-128.4,35.4
  c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2c-18.4-10.6-86.1-46.1-128.8-35.1c-0.5,0.1-1,0-1.2-0.4c-0.2-0.5,0-1,0.4-1.2
  C157.1,13.8,199,0,249.1,0 M249.1,199.7c0.3,0,0.6,0.2,0.8,0.4c74.5,56.7,202,196.7,163.1,236.3l0.1-0.1l0,0.1h-0.1
  C367.6,476.2,309.3,498.1,249,498c-60.3,0.1-118.6-21.8-163.9-61.6l-0.1-0.1l0.1,0.1c-38.9-39.6,88.6-179.7,163.1-236.3
  C248.4,199.9,248.8,199.7,249.1,199.7 M2218.3,103.6v245.1l-180.6-245.3l-29.6-0.1v298.5h33.2V162.1L2218.3,402l33.3,0.1V103.6
  H2218.3z M1835.6,96.6c-83.9,0-142.6,64.2-142.6,156.1c0,92,58.7,156.2,142.6,156.2c83.8,0,142.3-64.2,142.3-156.2
  C1977.9,160.9,1919.4,96.6,1835.6,96.6 M1835.6,377.3c-63.9,0-108.7-51.2-108.7-124.6c0-73.3,44.8-124.5,108.7-124.5
  c63.8,0,108.4,51.2,108.4,124.5C1943.9,326,1899.4,377.3,1835.6,377.3 M2322.4,372.8V262.4h164.3v-29.2h-164.3V132.6h175.5v-29.2
  h-208.9V402h211v-29.2H2322.4z"/>
</svg>
)

export default XboxOne
