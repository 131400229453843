import React from "react"

const NSCube = () => (
  <svg width="60px" height="68px" viewBox="0 0 60 68" version="1.1">
    <g id="Cube" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <polygon id="Path" fill="#FFD455" points="0 15 0 32 60 32 60 15 30 0"></polygon>
        <polygon id="Path" fill="#FFBF00" points="0 15 32 31 32 65 30 67.5 0 52.5"></polygon>
        <polygon id="Path" fill="#FFAF17" points="60 15 30 30 30 67.5 60 52.5"></polygon>
      </g>
    </g>
  </svg>
)

export default NSCube
