import React from "react"
import { Link } from "gatsby"

import Svg from "components/svgs/svg"
import * as s from "./footer.module.sass"

const socialData = require("data/social.json")

const Footer = () => {
  return (
    <div className={s.component}>
      <div className={s.svgs}>
        {socialData.map((social) => {
          if (!social.url) {
            return null
          }

          return (
            <Svg
              key={social.icon}
              parentClass={s.svg}
              id={social.icon}
              href={social.url}
            />
          )
        })}
      </div>

      <div className={s.links}>
        <Link className={s.link} to={`${process.env.SITE_URL}/privacy-policy/`}>
          Privacy Policy
        </Link>

        <Svg parentClass={s.cube} id="nsCube" />

        <Link className={s.link} to={`${process.env.SITE_URL}/terms-of-use/`}>
          Terms of use
        </Link>
      </div>
    </div>
  )
}

export default Footer
