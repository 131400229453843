export function getPostUrl(postSlug) {
  return `/blog/${postSlug}/`
}

export function getCategoryUrl(categorySlug) {
  return `/blog/category/${categorySlug}/`
}

export function camelize(str) {
  return str.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase())
}

// main function
export function smoothScrollTo(scrollTargetParam, speedParam, easingParam) {
  // scrollTargetYParam: the target scrollY property of the window
  // speedParam: time in pixels per second
  // easingParam: easing equation to use

  const el = document.getElementById(scrollTargetParam)

  if (!el) {
    return
  }

  let scrollY = window.scrollY || document.documentElement.scrollTop
  let scrollTargetY = el.offsetTop || 0
  let speed = speedParam || 100
  let easing = easingParam || "easeInOutQuint"
  let currentTime = 0

  // min time .1, max time .8 seconds
  const time = Math.max(
    0.1,
    Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
  )

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  const easingEquations = {
    easeOutSine: function (pos) {
      return Math.sin(pos * (Math.PI / 2))
    },
    easeInOutSine: function (pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1)
    },
    easeInOutQuint: function (pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5)
      }
      return 0.5 * (Math.pow(pos - 2, 5) + 2)
    },
  }

  // add animation loop
  function tick() {
    currentTime += 1 / 60

    const p = currentTime / time
    const t = easingEquations[easing](p)

    if (p < 1) {
      window.requestAnimationFrame(tick)
      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }

  // call it once to get started
  tick()
}
