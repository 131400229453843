import React from "react"

const PS4 = () => (
<svg
   xmlns="https://www.w3.org/2000/svg"
   viewBox="0 0 528 109.90667"
   height="109.90667"
   width="528"
   version="1.1"><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,109.90667)"
     id="g10"><g
       transform="scale(0.1)"
       id="g12"><path
         id="path14"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 1012.46,47.1953 c -1.44,-0.9258 -3.4,-1.4023 -5.97,-1.4023 h -12.572 v 12.8593 h 13.372 c 1.82,0 3.25,-0.2617 4.38,-0.8085 1.87,-0.9571 2.76,-2.6954 2.76,-5.4219 0,-2.5274 -0.63,-4.2188 -1.97,-5.2266 z m 10.98,-23.9414 c -0.52,0.1641 -1,0.8555 -1.38,1.9609 -0.05,0.7618 -0.21,1.8868 -0.32,3.4766 l -0.27,5.8008 c -0.1,2.7734 -0.52,4.707 -1.6,6.043 -0.69,0.832 -1.59,1.6914 -2.66,2.2187 1.39,0.9766 2.56,1.9805 3.42,3.3203 1.22,1.8164 1.81,4.1016 1.81,6.9258 0,5.3906 -2.3,9.1172 -6.72,11.1016 -2.29,1.1132 -5.18,1.5429 -8.59,1.5429 h -20.087 -0.969 V 64.7852 21.3164 20.3125 h 0.969 5.855 1.02 v 1.0039 17.6016 h 12.362 c 2.19,0 3.95,-0.3125 4.91,-0.6758 1.53,-0.8594 2.45,-2.3633 2.5,-4.9141 l 0.37,-7.2148 c 0.16,-1.6875 0.22,-2.8594 0.32,-3.5547 0.11,-0.6953 0.33,-1.2422 0.54,-1.6914 l 0.32,-0.5547 h 0.59 7.2 1.01 v 1.0039 1.0274 0.5781 l -0.6,0.332" /><path
         id="path16"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 1003.4,8.3125 c -9.052,0 -17.646,3.4844 -23.935,9.9297 -6.445,6.3476 -9.91,14.9219 -9.91,23.9844 0,9.0039 3.465,17.5 9.91,23.8984 6.289,6.4922 14.883,9.9609 23.935,9.9609 9.06,0 17.55,-3.4687 23.89,-9.9609 6.5,-6.3984 9.92,-14.8945 9.92,-23.8984 0,-18.8086 -15.2,-33.9141 -33.81,-33.9141 z m 28.84,62.6172 c -7.67,7.8203 -17.96,12.0312 -28.84,12.0312 -22.498,0 -40.685,-18.3437 -40.685,-40.7343 0,-10.9258 4.223,-21.1719 11.898,-28.8282 7.676,-7.73434 17.86,-11.9609 28.787,-11.9609 10.88,0 21.17,4.22656 28.84,11.9609 7.63,7.6563 11.84,17.9024 11.84,28.8282 0,10.7734 -4.21,20.9648 -11.84,28.7031" /><path
         id="path18"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 175.348,158.125 c 25.804,-8.445 72.465,-6.062 104.238,5.352 l 86.102,31.25 V 104.23 c -5.493,-0.91 -11.516,-1.914 -17.114,-2.824 -86.152,-14.0622 -177.976,-8.2146 -268.3396,21.602 -84.29299,23.711 -98.3633,73.097 -59.9336,101.621 35.5586,26.34 95.9222,46.074 95.9222,46.074 l 249.465,88.66 V 258.32 L 186.172,194.031 c -31.781,-11.363 -36.567,-27.527 -10.824,-35.906" /><path
         id="path20"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 1058.8,193.438 c -20.9,-26.336 -71.976,-45.145 -71.976,-45.145 L 606.469,11.6797 V 112.395 l 279.902,99.8 c 31.774,11.36 36.68,27.453 10.875,35.879 -25.805,8.449 -72.449,6.035 -104.234,-5.32 L 606.469,177.012 v 104.511 l 10.703,3.625 c 0,0 53.965,19.122 129.676,27.567 75.812,8.215 168.636,-1.145 241.468,-28.809 82.104,-25.945 91.334,-64.187 70.484,-90.468" /><path
         id="path22"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 642.672,364.949 v 257.625 c 0,30.168 -5.555,57.957 -33.973,65.86 -21.746,7.089 -35.183,-13.18 -35.183,-43.352 V 0 L 399.441,55.2422 V 824.336 C 473.492,810.621 581.25,778.156 639.242,758.703 786.566,708.043 836.52,645.082 836.52,503.262 836.52,365 751.168,312.551 642.672,364.949" /><path
         id="path24"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 3582.52,233.418 c 0,-2.078 -1.65,-3.672 -3.58,-3.672 H 3102.2 c -3.89,0 -5.92,0.945 -6.47,2.383 -0.57,2.266 0.7,3.918 3.49,5.82 l 474.49,292.117 c 3.32,2.036 8.81,1.719 8.81,-4.793 z m 214.77,-3.848 -105.53,0.078 h 3.67 c -2,0 -3.67,1.641 -3.67,3.555 v 341.863 c 0,52.532 -36.36,69.754 -87.38,38.247 L 2991.99,237.219 c -24.09,-14.778 -32.16,-31.465 -26.34,-43.828 5.02,-10.625 18.24,-18.032 44.74,-18.032 l 568.77,-0.046 c 1.59,-0.118 2.88,-1.211 3.26,-2.676 v -47.981 c 0,-2.027 1.6,-3.672 3.51,-3.672 h 102.27 c 1.91,0 3.56,1.645 3.56,3.672 v 46.985 c 0,2.031 1.67,3.672 3.58,3.672 h 101.95 c 2.01,0 3.68,1.652 3.68,3.632 v 47.121 c 0,1.864 -1.67,3.504 -3.68,3.504" /><path
         id="path26"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 2027.55,355.898 h -428.29 c -39.55,0 -67.18,-33.437 -67.18,-81.296 V 124.563 c 0,-1.985 -1.59,-3.579 -3.57,-3.579 h -101.83 c -1.97,0 -3.58,1.555 -3.58,3.579 l -0.05,150.039 c 0,77.339 52.26,135.609 121.62,135.609 h 446.79 c 47.89,0 70.59,34.457 70.59,82.285 0,47.859 -22.78,82.309 -70.59,82.309 h -564.78 c -2.03,0 -3.63,1.601 -3.63,3.57 v 47.184 c 0,1.929 1.6,3.519 3.57,3.519 h 600.93 c 96.35,0 143.47,-59.383 143.47,-136.691 0,-77.258 -47.12,-136.489 -143.47,-136.489" /><path
         id="path28"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 3008.79,574.91 h -296.08 c -47.81,0 -70.54,-34.43 -70.54,-82.316 V 257.527 c 0,-77.324 -47.24,-136.543 -143.56,-136.543 h -348.26 c -1.99,0 -3.58,1.594 -3.58,3.579 v 47.175 c 0,2.024 1.59,3.575 3.5,3.575 h 312.29 c 47.86,0 70.59,34.421 70.59,82.265 v 234.918 c 0,77.234 47.15,136.641 143.47,136.641 l 332.17,0.047 c 1.91,0 3.51,-1.586 3.51,-3.571 v -47.179 c 0,-1.918 -1.55,-3.524 -3.51,-3.524" /><path
         id="path30"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 3952.53,121.172 v 46.34 l -15.57,-46.34 h -7.44 l -15.59,46.34 v -46.34 h -7.45 v 57.051 h 10.39 l 16.37,-48.504 16.42,48.504 H 3960 v -57.051 h -7.47" /><path
         id="path32"
         style={{fill: "#000000", fillOpacity: 1, fillRule: "nonzero", stroke: "none"}}
         d="m 3880.56,171.777 v -50.605 h -7.26 v 50.605 h -18.86 v 6.446 h 44.78 v -6.446 h -18.66" /></g></g></svg>
)

export default PS4
