import React from "react"
import { Link } from "gatsby"

import { camelize } from "components/utils"
import cx from "classnames"
import * as s from "./button.module.sass"

const Button = props => {
  const {
    id,
    href,
    to,
    size,
    color,
    children,
    onClick,
    onSubmit,
    type,
    fullwidth,
    outlined,
    isDisabled,
    isLoading,
    isStatic,
    parentClass,
    unstyled = false,
  } = props;

  const sizeClass = camelize(`is-${size}`);
  const colorClass = camelize(`is-${color}`);

  const componentClass = cx({
    "button": !unstyled,
    [s.button]: !unstyled,
    [s[sizeClass]]: size !== '',
    [s[colorClass]]: color !== '',
    [s.outlined]: outlined,
    [s.fullwidth]: fullwidth,
    [s.disabled]: isDisabled,
    [s.static]: isStatic,
    [s.loading]: isLoading,
    [parentClass]: parentClass,
  });

  let attr = {
    className: componentClass,
    id,
    onClick,
    onSubmit,
    type,
  };

  // Internal link
  if (to && to !== '') {
    return (
      <Link to={to} {...attr}>
        {children}
      </Link>
    )
  }

  // External link
  if (href && href !== '') {
    attr.target = "_blank";

    return (
      <a href={href} {...attr}>
        {children}
      </a>
    )
  }

  // Static button
  if (isDisabled || isStatic) {
    return (
      <span {...attr}>
        {children}
      </span>
    )
  }

  if (isLoading) {
    return (
      <span {...attr}>
        Loading
      </span>
    )
  }

  // Basic button
  return (
    <button {...attr}>
      {children}
    </button>
  )

}

export default Button
