import React from "react"

import Button from "components/form/button"
import AppStoreSvg from "./app-store"
import ArrowSvg from "./arrow"
import ChevronLeftSvg from "./chevron-left"
import ChevronRightSvg from "./chevron-right"
import EmailSvg from "./email"
import FacebookSvg from "./facebook"
import DiscordSvg from "./discord"
import GithubSvg from "./github"
import InstagramSvg from "./instagram"
import LinkedInSvg from "./linkedin"
import NSCubeSvg from "./netspeak-games-cube"
import NSIconSvg from "./netspeak-games-icon"
import NSLogoSvg from "./netspeak-games-logo"
import NSLogoLightSvg from "./netspeak-games-logo-light"
import PS4Svg from "./ps4"
import SteamSvg from "./steam"
import TwitterSvg from "./twitter"
import XboxOneSvg from "./xbox-one"
import YouTubeSvg from "./youtube"

import cx from "classnames"
import * as s from "./svg.module.sass"

const Svg = props => {
  const {
    id,
    light,
    parentClass,
    to,
    href,
  } = props;

  if (!(id in ICONS)) {
    return null;
  }

  const componentClass = cx({
    [s.component]: true,
    [s.light]: light,
    [s[id]]: id,
    [parentClass]: parentClass,
  });

  const content = ICONS[id].svg;

  if (to) {
    return (
      <Button unstyled to={to} parentClass={componentClass}>
        {content}
      </Button>
    )
  }

  if (href) {
    return (
      <Button unstyled href={href} parentClass={componentClass}>
        {content}
      </Button>
    )
  }

  return (
    <figure className={componentClass}>
      {content}
    </figure>
  )
}

const ICONS = {
  appStore: {
    "name": "appStore",
    "svg": <AppStoreSvg />,
  },
  arrow: {
    "name": "arrow",
    "svg": <ArrowSvg />,
  },
  chevronLeft: {
    "name": "chevronLeft",
    "svg": <ChevronLeftSvg />,
  },
  chevronRight: {
    "name": "chevronRight",
    "svg": <ChevronRightSvg />,
  },
  email: {
    "name": "email",
    "svg": <EmailSvg />,
  },
  facebook: {
    "name": "facebook",
    "svg": <FacebookSvg />,
  },
  discord: {
    "name": "discord",
    "svg": <DiscordSvg />,
  },
  github: {
    "name": "github",
    "svg": <GithubSvg />,
  },
  instagram: {
    "name": "instagram",
    "svg": <InstagramSvg />,
  },
  linkedin: {
    "name": "linkedin",
    "svg": <LinkedInSvg />,
  },
  nsCube: {
    "name": "nsCube",
    "svg": <NSCubeSvg />,
  },
  nsIcon: {
    "name": "nsIcon",
    "svg": <NSIconSvg />,
  },
  nsLogo: {
    "name": "nsLogo",
    "svg": <NSLogoSvg />,
  },
  nsLogoLight: {
    "name": "nsLogoLight",
    "svg": <NSLogoLightSvg />,
  },
  ps4: {
    "name": "ps4",
    "svg": <PS4Svg />,
  },
  steam: {
    "name": "steam",
    "svg": <SteamSvg />,
  },
  twitter: {
    "name": "twitter",
    "svg": <TwitterSvg />,
  },
  xboxOne: {
    "name": "xboxOne",
    "svg": <XboxOneSvg />,
  },
  youtube: {
    "name": "youtube",
    "svg": <YouTubeSvg />,
  },
};

export default Svg
